<template lang="pug">
.lfilters
  .lfilters__title {{ $t('фильтр') }}
  p {{ $t('по стране') }}
  LAutocomplite.lfilters__input.mt-1(
    v-model="country"
    @input="inputCountry"
    :placeholder="$t('начните вводить название страны')"
    :preloader="countryPreloader"
  )
    template(slot="items")
      .lfilters__droplist-item(
        v-if="countriesList.length > 0"
        v-for="country in countriesList"
        @click="setCountry(country)"
      ) {{ country.name }}

  .lfilters__selected
    .lfilters__selected-item(v-for="sc, index in selectedCountries")
      span {{ sc.name }}
      LIcon(name="close").lfilters__selected-remove(@click="removeSelectedCountry(index)")

  p.mt-2 {{ $t('по городу') }}
    LAutocomplite.lfilters__input.mt-1(
      v-model="city"
      @input="inputCity"
      placeholder="start typing the city name"
      :preloader="cityPreloader"
    )
      template(slot="items")
        .lfilters__droplist-item(
          v-if="citiesList.length > 0"
          v-for="city in citiesList"
          @click="setCity(city)"
        ) {{ city.name }}

    .lfilters__selected
      .lfilters__selected-item(v-for="sc, index in selectedCities")
        span {{ sc.name }}
        LIcon(name="close").lfilters__selected-remove(@click="removeSelectedCity(index)")

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LFilters',
  components: {
    LAutocomplite: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAutocomplite'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
  },
  props: {
    filters: {
      type: [Array, Object],
      default: null
    }
  },
  created () {
    if (this.filters !== null && Object.keys(this.filters).length > 0) {
      if (this.filters.countries !== null) {
        this.selectedCountries = this.filters.countries
      }
    }
  },
  data () {
    return {
      country: null,
      countriesList: [],
      selectedCountries: [],
      countryPreloader: false,

      citiesList: [],
      city: null,
      selectedCities: [],
      cityPreloader: false,
    }
  },
  methods: {
    ...mapActions('dictonary', [ 'countries', 'cities']),

    inputCity (event) {
      if (event !== null & event.length >= 3) {
        this.cityPreloader = true
        this.cities(event)
        .then((response) => {
          this.citiesList = response.data.data.data
        })
        .finally(() => {
          this.cityPreloader = false
        })
      }
    },

    inputCountry (query) {
      // TODO add debounce
      if (query !== null && query.length >= 3) {
        this.countryPreloader = true
        this.countries(query)
        .then((response) => {
          this.countriesList = response.data.data.data
        })
        .finally(() => {
          this.countryPreloader = false
        })
      }
    },

    setCountry (country) {

      let dublicateIndex = this.selectedCountries.findIndex(item => item.slug === country.slug)
      if (dublicateIndex < 0) {
        this.selectedCountries.push(country)
      }
      this.country = null
      this.countriesList = []
      this.emitFilters()
    },

    setCity (city) {
      let dublicateIndex = this.selectedCities.findIndex(item => item.slug === city.slug)
      if (dublicateIndex < 0) this.selectedCities.push(city)

      this.city = null
      this.citiesList = []
      this.emitFilters()
    },

    removeSelectedCountry (index) {
      this.selectedCountries.splice(index, 1)
      this.emitFilters()
    },

    removeSelectedCity (index) {
      this.selectedCities.splice(index, 1)
      this.emitFilters()
    },

    emitFilters () {
      const filters = {}

      if (this.selectedCountries.length > 0) {
        filters['countries'] = this.selectedCountries
      }

      if (this.selectedCities.length > 0) {
        filters['cities'] = this.selectedCities
      }


      this.$emit('change', filters)
    }
  }
}
</script>

<style lang="scss">
.lfilters {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 60px 30px;
  margin-bottom: 30px;
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 30px;
    text-transform: capitalize;
  }

   &__droplist-item {
    padding: 10px 10px;
    cursor: pointer;
  }
  &__selected-item {
    font-size: 9px;
    align-items: center;
    height: 15px;
    padding: 5px 5px 5px 10px;
    border: solid 1px #757373;
    display: inline-flex;
    border-radius: 15px;
    margin-top: 10px;
    margin-right: 10px;
  }
  &__selected-remove {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    cursor: pointer;
  }
  &__input {
    & .linput {
      height: 55px;
      border-radius: 5px;
      padding-right: 10px;
      padding-left: 10px;
      border: solid 1px #888A99;
      & input::placeholder {
        font-size: 10px;
      }
      & .linput__preloader {
        width: 20px;
      }
      @media (min-width: 960px) {
        height: 30px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}
</style>