<template lang="pug">
.lmessages-view
  .lmessages-view__header
    LAvatar
    .lmessages-view__info
      span First Last
      span Рекламодатель

    span.lmessages-view__online

  .lmessages-view__messages

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LMessagesView',
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar')
  },
  props: {
    user: {
      type: [Array, Object],
      default: null
    }
  },
  data () {
    return {
    }
  }

}
</script>

<style lang="scss">
.lmessages-view {
  width: 545px;
  margin: 0 auto;
  padding-top: 70px;
  border-bottom: solid 2px #C4C4C4;
  padding-bottom: 25px;
  &__header {
    display: flex;
  }
  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    justify-content: center;
    & > span {
      margin: 3px 0;
      &:first-child {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  &__online {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #6FF885;
    margin-left: 10px;
    position: relative;
    top:18px;
  }
}
</style>