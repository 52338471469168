<template lang="pug">
  <router-link class="lmessage-user" to="/app/messages/uuidstub">
    LAvatar.lmessage-user__avatar
    .lmessage-user__data
      .lmessage-user__info
        span.lmessage-user__name FirstName LastName
        span.lmessage-user__online

      .lmessage-user__message Я: Lorem ipsum dolor sit amen
      .lmessage-user__time 5 мин.

  </router-link>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LMessageUser',
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar')
  },
  props: {
    user: {
      type: [Array, Object],
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS']),
  }

}
</script>

<style lang="scss">
.lmessage-user {
  background: #FAF9F9;
  border-radius: 5px;
  text-decoration: none;
  display: block;
  padding: 10px 10px 10px 20px;
  display: flex;
  margin-bottom: 10px;
  &:focus {
    outline: 0;
  }
  &__avatar {
    flex-shrink: 0;
    margin-right: 16px;
  }
  &__name {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__data {
    width: calc(100% - 68px - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__message {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #757373;
  }
  &__time {
    color: #757373;
    margin-left: auto;
    display: inline;
    font-size: 14px;
  }
  &__online {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #6FF885;
    margin-left: 10px;
  }
}
</style>